import loader from '@/assets/png/angle-gradient.png';
import classNames from 'classnames';
import { useSpinner } from '@/store/hooks';
import './loading.scss';
import { SpinnerArea } from '@/interface/common';

export interface LoadingPropTypes {
  isVerdigris?: boolean;
}

const Loading = ({ isVerdigris = true }: LoadingPropTypes) => {
  const { spinnerType, requestCount } = useSpinner();
  if (spinnerType === SpinnerArea.None && requestCount === 0) return null;
  return (
    <div
      className={classNames(
        'fixed inset-0 flex justify-center items-center z-50 bg-white/75 transition duration-1000',
        {
          'mt-[300px]': Object.values(SpinnerArea).slice(1, 4).includes(spinnerType),
          'ml-[0px] mt-[105px]': spinnerType === SpinnerArea.Panel,
          'ml-[138px] mt-[421px] w-[255px] h-[200px]': spinnerType === SpinnerArea.Dropdown,
        }
      )}
    >
      <div className="relative">
        {isVerdigris ? (
          <>
            <div
              className="loading-spinner-inner-content"
              style={{ width: '64px', height: '64px' }}
            >
              <img alt="Loading…" className="loading-spinner-angle-gradient" src={loader} />
            </div>
            <svg className="loading-spinner-clip-mask">
              <defs>
                <clipPath id="vdgSpinnerClipPath" clipPathUnits="objectBoundingBox">
                  <path d="M0.517802437,0.996128707 L0.905159306,0.759711511 C0.913203188,0.754802054 0.918125,0.745959464 0.918125,0.736417197 L0.918125,0.263582804 C0.918125,0.254040534 0.913203188,0.245197943 0.905159306,0.240288486 L0.517802437,0.00387129144 C0.509345233,-0.00129043048 0.49877977,-0.00129043048 0.490322566,0.00387129144 L0.102965699,0.240288486 C0.0949218172,0.245197943 0.09,0.254040534 0.09,0.263582804 L0.09,0.736417197 C0.09,0.745959464 0.0949218172,0.754802054 0.102965699,0.759711511 L0.490322566,0.996128707 C0.49877977,1.00129043 0.509345233,1.00129043 0.517802437,0.996128707 Z M0.143409934,0.721080421 L0.143371485,0.278987991 L0.504323032,0.0590083313 L0.864716583,0.279057454 L0.864797887,0.720989364 L0.504176677,0.941213156 L0.143409934,0.721080421 Z"></path>
                </clipPath>
              </defs>
            </svg>
          </>
        ) : (
          <svg className="spinner spinner-dash" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
          </svg>
        )}
      </div>
    </div>
  );
};

export default Loading;
