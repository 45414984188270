import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { BuildingStateType } from '..';
import { cloneDeep } from 'lodash';
import { PanelDetailType } from '@/interface/panelTypes';
import { findIdByEndUseName } from '@/pages/panelConfiguration/utils/endUse';
import { Side } from '@/interface/side';
import { getMinIdInCircuits } from './breakers';
import { DocBreaker, DocCircuit, DocType } from '@/interface/docTypes';

export default {
  updateCircuit: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      type: 'phase' | 'clampsize' | 'polarity' | 'add' | 'delete' | undefined;
      value?: string | number | boolean;
      breakerId: number;
      circuitId?: number;
      side?: Side;
    }>
  ) => {
    const { side, breakerId, circuitId, value, type } = action.payload;
    const cxDoc = cloneDeep(state.cxDoc);
    const currentPanel = cloneDeep(state.currentPanel);
    let targetSideBreaker: DocBreaker[] = [];
    if (side === 'left') {
      targetSideBreaker = cxDoc.leftBreakers.breakers;
    }
    if (side === 'right') {
      targetSideBreaker = cxDoc.rightBreakers.breakers;
    }
    if (side === 'archived') {
      targetSideBreaker = cxDoc.archiveBreakers;
    }

    const breakerIndex = targetSideBreaker.findIndex((brk) => brk.id === breakerId);
    const circuitIndex = targetSideBreaker[breakerIndex].circuits.findIndex(
      (circuit) => circuit.id === circuitId
    );

    switch (type) {
      case 'phase':
        if (!value) return;
        targetSideBreaker[breakerIndex].circuits[circuitIndex].phase = value.toString();
        break;
      case 'clampsize':
        if (!value) return;
        targetSideBreaker[breakerIndex].circuits[circuitIndex].clampSize = Number(value);
        break;
      case 'polarity':
        targetSideBreaker[breakerIndex].circuits[circuitIndex].reverse = !!value;
        break;
      case 'add':
        addCircuit(targetSideBreaker, breakerIndex, side, cxDoc, state);
        break;
      case 'delete':
        deleteCircuit(
          targetSideBreaker,
          breakerIndex,
          circuitIndex,
          circuitId,
          side,
          cxDoc,
          currentPanel
        );
        break;
      default:
        break;
    }
    state.cxDoc = cxDoc;
  },
  moveCircuit: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      fromBreakerId: number;
      toBreakerId: number;
      circuitId: number;
      addIndex: number;
    }>
  ) => {
    const cxDoc = cloneDeep(state.cxDoc);
    const { fromBreakerId, toBreakerId, circuitId, addIndex } = action.payload;
    let sourceBreaker: DocBreaker = { id: 0, name: '', rating: 0, circuits: [] };
    let targetBreaker: DocBreaker = { id: 0, name: '', rating: 0, circuits: [] };
    const fromLeftIndex = cxDoc.leftBreakers.breakers.findIndex((brk) => brk.id === fromBreakerId);
    const fromRightIndex = cxDoc.rightBreakers.breakers.findIndex(
      (brk) => brk.id === fromBreakerId
    );
    const fromArchivedIndex = cxDoc.archiveBreakers.findIndex((brk) => brk.id === fromBreakerId);
    const toLeftIndex = cxDoc.leftBreakers.breakers.findIndex((brk) => brk.id === toBreakerId);
    const toRightIndex = cxDoc.rightBreakers.breakers.findIndex((brk) => brk.id === toBreakerId);
    const toArchivedIndex = cxDoc.archiveBreakers.findIndex((brk) => brk.id === toBreakerId);
    switch (true) {
      case fromLeftIndex > -1:
        cxDoc.availableLeftChannels.push(circuitId);
        sourceBreaker = cxDoc.leftBreakers.breakers[fromLeftIndex];
        break;
      case fromRightIndex > -1:
        cxDoc.availableRightChannels.push(circuitId);
        sourceBreaker = cxDoc.rightBreakers.breakers[fromRightIndex];
        break;
      case fromArchivedIndex > -1:
        sourceBreaker = cxDoc.archiveBreakers[fromArchivedIndex];
        break;
      default:
        break;
    }
    switch (true) {
      case toLeftIndex > -1:
        cxDoc.availableLeftChannels.pop();
        targetBreaker = cxDoc.leftBreakers.breakers[toLeftIndex];
        break;
      case toRightIndex > -1:
        cxDoc.availableRightChannels.pop();
        targetBreaker = cxDoc.rightBreakers.breakers[toRightIndex];
        break;
      case toArchivedIndex > -1:
        targetBreaker = cxDoc.archiveBreakers[toArchivedIndex];
        break;
      default:
        break;
    }
    const circuitIndex = sourceBreaker.circuits.findIndex((cir) => cir.id === circuitId);
    // delete it
    const temCircuit = sourceBreaker.circuits.splice(circuitIndex, 1)[0];
    const circuitToAdd = {
      ...temCircuit,
      clampSize: temCircuit?.clampSize || 60,
    };
    // push into new breaker
    if (addIndex === 0) {
      // current circuit is the top of this breaker
      targetBreaker.circuits.unshift(circuitToAdd);
    } else {
      targetBreaker.circuits.splice(addIndex, 0, circuitToAdd);
    }
    if (sourceBreaker.circuits.length === 0) {
      if (fromRightIndex > -1) {
        cxDoc.rightBreakers.breakers.splice(fromRightIndex, 1);
      }
      if (fromArchivedIndex > -1) {
        cxDoc.archiveBreakers.splice(fromArchivedIndex, 1);
      }
      if (fromLeftIndex > -1) {
        cxDoc.leftBreakers.breakers.splice(fromLeftIndex, 1);
      }
    }
    state.cxDoc = cxDoc;
  },
};

const addCircuit = (
  targetBreaker: DocBreaker[],
  breakerIndex: number,
  side: Side | undefined,
  cxDoc: DocType,
  state: WritableDraft<BuildingStateType>
) => {
  const endUseId = Number(findIdByEndUseName('Unlabelled', state.endUses));
  const phaseNameArr = ['A', 'B', 'C'];
  const nameArr = [0, 0, 0];
  targetBreaker[breakerIndex].circuits.forEach((circuit) => {
    if (circuit.name.indexOf('(A)') > -1) {
      nameArr[0] = 1;
    }
    if (circuit.name.indexOf('(B)') > -1) {
      nameArr[1] = 1;
    }
    if (circuit.name.indexOf('(C)') > -1) {
      nameArr[2] = 1;
    }
  });
  let channel = 0;
  if (side === 'left') {
    channel = cxDoc.availableLeftChannels.pop() || 0;
  }
  if (side === 'right') {
    channel = cxDoc.availableRightChannels.pop() || 0;
  }
  const phaseName = phaseNameArr[nameArr.findIndex((exist) => !exist)];
  const newCircuit: DocCircuit = {
    id: getMinIdInCircuits(cxDoc),
    name: `${targetBreaker[breakerIndex].name} (${phaseName})`,
    phase: phaseName,
    endUseId: endUseId,
    reverse: false,
    channel: channel,
    clampSize: 60,
  };
  targetBreaker[breakerIndex].circuits.push(newCircuit);
};

const deleteCircuit = (
  targetSideBreaker: DocBreaker[],
  breakerIndex: number,
  circuitIndex: number,
  circuitId: number | undefined,
  side: Side | undefined,
  cxDoc: DocType,
  currentPanel: PanelDetailType | null
) => {
  targetSideBreaker[breakerIndex].circuits.splice(circuitIndex, 1);
  if (side === 'left') {
    cxDoc.availableLeftChannels.push(
      currentPanel?.attachments.find((att) => att.circuit === circuitId)?.channel || 0
    );
    targetSideBreaker[breakerIndex].circuits.length === 0 &&
      cxDoc.leftBreakers.breakers.splice(breakerIndex, 1);
  }
  if (side === 'right') {
    cxDoc.availableRightChannels.push(
      currentPanel?.attachments.find((att) => att.circuit === circuitId)?.channel || 0
    );
    targetSideBreaker[breakerIndex].circuits.length === 0 &&
      cxDoc.rightBreakers.breakers.splice(breakerIndex, 1);
  }
  if (side === 'archived' && targetSideBreaker[breakerIndex].circuits.length === 0) {
    cxDoc.archiveBreakers.splice(breakerIndex, 1);
  }
};
