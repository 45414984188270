import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useRoutes, BrowserRouter } from 'react-router-dom';
import routes from './routes';
import Loading from '@/components/loading';
import packageJson from '../package.json';

function RouteElement() {
  return useRoutes(routes);
}

const App: React.FC = () => {
  const { isLoading, error } = useAuth0();
  const [isGmapLoaded, setIsGmapLoaded] = useState(false);

  const initMap = () => {
    setIsGmapLoaded(true);
  };

  useEffect(() => {
    function appendGmapScript() {
      if (isGmapLoaded) return;
      (window as any).initMap = initMap;
      const gmapScriptEl = document.createElement(`script`);
      gmapScriptEl.setAttribute('async', 'async');
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`;
      document.querySelector(`head`)?.insertAdjacentElement(`beforeend`, gmapScriptEl);
    }
    function insertVersion() {
      const versionElement = document.querySelector('meta[name="version"]');
      if (!versionElement) {
        const meta = document.createElement('meta');
        meta.setAttribute('name', 'version');
        meta.setAttribute('content', packageJson.version);
        document.head.insertBefore(meta, document.head.firstChild);
      } else if (versionElement.getAttribute('content') !== packageJson.version) {
        versionElement.setAttribute('content', packageJson.version);
      }
    }
    appendGmapScript();
    insertVersion();
  }, []);

  if (error) {
    return <h1 className="text-center mt-56">Oops... {error.message}</h1>;
  }

  if (isLoading || !isGmapLoaded) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <RouteElement />
    </BrowserRouter>
  );
};

export default App;
