import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PanelDetailType } from '@/interface/panelTypes';
import type { RootState } from './index';
import { DataType, HierarchyNode } from '@/interface/hierarchy';

export type CurrentAccount = {
  id: string;
  name: string;
};
export type CurrentBuilding = {
  id: string;
  name: string;
  slug: string;
  timezone: string;
};
export type CurrentPanel = {
  id: string;
  name: string;
};
export interface Hierarchy {
  currentAccount: CurrentAccount;
  currentBuilding: CurrentBuilding;
  buildingHierarchy: HierarchyNode;
  buildingPanels: PanelDetailType[];
  archivedBreakers: number[];
  mainFeeders: number[];
}

export const initialState: Hierarchy = {
  currentAccount: {
    id: '',
    name: '',
  },
  currentBuilding: {
    id: '',
    name: '',
    slug: '',
    timezone: '',
  },
  buildingHierarchy: {
    id: '',
    name: '',
    type: DataType.Building,
    children: [],
  },
  buildingPanels: [],
  archivedBreakers: [],
  mainFeeders: [],
};

const hierarchySlice = createSlice({
  name: 'hierarchy',
  initialState,
  reducers: {
    setCurrentAccount: (state, action: PayloadAction<CurrentAccount>) => {
      state.currentAccount = action.payload;
    },
    setCurrentBuilding: (state, action: PayloadAction<CurrentBuilding>) => {
      state.currentBuilding = action.payload;
    },
    setBuildingHierarchy: (state, action: PayloadAction<HierarchyNode>) => {
      state.buildingHierarchy = action.payload;
    },
    setBuildingPanels: (state, action: PayloadAction<PanelDetailType[]>) => {
      state.buildingPanels = action.payload;
    },
    setArchivedBreakers: (state, action: PayloadAction<number[]>) => {
      state.archivedBreakers = action.payload;
    },
    setMainFeeders: (state, action: PayloadAction<number[]>) => {
      state.mainFeeders = action.payload;
    },
  },
});

export const {
  setCurrentAccount,
  setCurrentBuilding,
  setBuildingHierarchy,
  setBuildingPanels,
  setArchivedBreakers,
  setMainFeeders,
} = hierarchySlice.actions;
export const selectCurrentAccount = (state: RootState) => state.hierarchy.currentAccount;
export const selectCurrentBuilding = (state: RootState) => state.hierarchy.currentBuilding;
export const selectBuildingHierarchy = (state: RootState) => state.hierarchy.buildingHierarchy;
export const selectBuildingPanels = (state: RootState) => state.hierarchy.buildingPanels;
export const selectArchivedBreakers = (state: RootState) => state.hierarchy.archivedBreakers;
export const selectMainFeeders = (state: RootState) => state.hierarchy.mainFeeders;
export default hierarchySlice.reducer;
