import { EndUse } from '@/store/buildingSlice';

export const findIdByEndUseName = (endUseName: string, endUseList: EndUse[]) => {
  if (!endUseName) return '';
  return endUseList.find((endUse) => endUse.name === endUseName)?.id;
};

export const buildingUseOptions = [
  { value: 'commercial real estate', label: 'Commercial Real Estate' },
  { value: 'construction', label: 'Construction' },
  { value: 'data center', label: 'Data Center' },
  { value: 'food and beverage', label: 'Food & Beverage' },
  { value: 'government', label: 'Government' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'hospitality', label: 'Hospitality' },
  { value: 'logistics real estate', label: 'Logistics Real Estate' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'other', label: 'Other' },
];
