import { coreBase, coreBaseV2, cxBase } from '@/config/api';
import { BuildingCreateType, BuildingUpdateType } from '@/interface/buildingCreator';
import { SpinnerArea } from '@/interface/common';
import { PanelDetailType, PanelHistoryItemType } from '@/interface/panelTypes';
import request from '@/utils/request';

type CommissionPanelType = {
  id?: string;
  'activation-code': string;
  buildingId: string;
  earthGroundedNeutral: boolean;
  notes: string;
  uid: string;
};

type HistoryResponse = { history: PanelHistoryItemType[]; current: number; pages: number };

export function getPanelInfo(panelId: number) {
  return request(`${cxBase}/panels/${panelId}`, { spinnerType: SpinnerArea.Panel });
}

export function commissionPanel(data: CommissionPanelType) {
  return request({
    url: data.id ? `${cxBase}/panels/${data.id}` : `${cxBase}/panels`,
    method: 'post',
    data,
    spinnerType: SpinnerArea.Panel,
  });
}

export function updatePanelInfo(panelId: number, panelDetail: PanelDetailType) {
  return request({
    url: `${cxBase}/panels/${panelId}`,
    method: 'put',
    data: panelDetail,
    spinnerType: SpinnerArea.Building,
  });
}

export function deletePanelInfo(panelId: number) {
  return request({
    url: `${cxBase}/panels/${panelId}`,
    method: 'delete',
    spinnerType: SpinnerArea.Building,
  });
}

export function getBuildings(): any {
  return request(`${coreBaseV2}/buildings`);
}

export function getBuilding(id: string): any {
  return request({
    url: `${coreBaseV2}/buildings/${id}`,
    params: {
      include: 'panels.breakers',
    },
    spinnerType: SpinnerArea.Building,
  });
}

export function createBuilding(data: BuildingCreateType) {
  return request({
    url: `${coreBaseV2}/buildings`,
    method: 'post',
    data,
  });
}

export function updateBuilding(data: BuildingUpdateType) {
  return request({
    url: `${coreBaseV2}/buildings/${data.data.id}`,
    method: 'patch',
    data,
  });
}

export function getEndUse(ids: string[]) {
  return request({
    url: `${coreBase}/end-uses?filter[id]=${ids.join(',')}`,
    spinnerType: SpinnerArea.Panel,
  });
}

export function getPanelHistory(query: {
  buildingId: string;
  panelId: number;
  perPage: number;
  page: number;
  showPanelLoading?: boolean;
}): Promise<HistoryResponse> {
  return request({
    url: `${cxBase}/buildings/${query.buildingId}/panels/${query.panelId}/history`,
    params: {
      'per-page': query.perPage || 10,
      page: query.page || 1,
    },
    method: 'get',
    spinnerType: query.showPanelLoading ? SpinnerArea.FullPanel : SpinnerArea.Dropdown,
  });
}

export function getBuildingCxPanels(
  buildingId: string,
  page = 1,
  perPage = 10,
  disableAbort?: boolean
): Promise<{
  current: number;
  pages: number;
  panels: PanelDetailType[];
}> {
  return request({
    url: `${cxBase}/buildings/${buildingId}/panels`,
    params: {
      'per-page': perPage,
      page,
    },
    spinnerType: SpinnerArea.None,
    abortToken: disableAbort ? undefined : buildingId,
  });
}
