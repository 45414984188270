import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { PanelDetailType, PanelHistoryType, PanelListType } from '@/interface/panelTypes';
import commonReduces from './reducers/common';
import { getEndUse, getPanelInfo } from '@/api/buildingApi';
import type { Building, BuildingBrief } from '@/interface/building';
import { Include } from '@/interface/include';
import { DocType } from '@/interface/docTypes';
import initDoc from './initialize/doc';
export interface EndUse {
  name: string;
  id: string;
}

export interface BuildingStateType {
  buildings: Building[];
  buildingsBrief: BuildingBrief[];
  editing: boolean;
  cxDoc: DocType;
  panelList: PanelListType[];
  currentPanel: PanelDetailType | null;
  currentPanelId: number;
  include: Include[];
  endUses: EndUse[];
  status: 'idle' | 'loading' | 'failed';
  historicalView: boolean;
  historyCxDoc: DocType;
  expandedView: '' | 'history' | 'current';
  historical: PanelHistoryType;
}

export const defaultDoc = {
  id: 0,
  name: '',
  phases: 0,
  notes: null,
  voltage: 0,
  earthGroundedNeutral: false,
  mains: [],
  version: 1,
  isCorrupted: false,
  isNoResource: false,
  leftBreakers: {
    totalSensor: 0,
    breakers: [],
  },
  rightBreakers: {
    totalSensor: 0,
    breakers: [],
  },
  archiveBreakers: [],
  dataGateWay: { uid: '', chain1: [], chain2: [] },
  availableLeftChannels: [],
  availableRightChannels: [],
};

const initialState: BuildingStateType = {
  editing: false,
  currentPanel: null,
  currentPanelId: 0,
  cxDoc: { ...defaultDoc },
  panelList: [],
  expandedView: 'current',
  historicalView: false,
  historyCxDoc: { ...defaultDoc },
  historical: {
    currentVersion: 0,
    histories: [],
  },
  endUses: [],
  status: 'loading',
  buildings: [],
  buildingsBrief: [],
  include: [],
};

// when page init and change building should invoke this function to init data;
export const initPanelInfo = createAsyncThunk('building/panel', async (id: number) => {
  try {
    const panel = await getPanelInfo(id);
    const isFitUrl = window.location.href.includes(id.toString());
    // there is an edge case that when change account to fast, and new panel is noResource, it will return fast that last one.
    if (isFitUrl) {
      return panel;
    } else {
      return {
        isNoResource: true,
        panel: {
          id: '',
          name: 'New Panel',
        },
      };
    }
  } catch (error) {
    return {
      isNoResource: true,
      panel: {
        id,
        name: 'New Panel',
      },
    };
  }
});

export const initEndUse = createAsyncThunk(
  'building/fetchEndUse',
  async (ids: string[]): Promise<{ name: string; id: string }[]> => {
    try {
      const endUseList: { name: any; id: any }[] = [];
      const endUseRs: any = await getEndUse(ids);
      endUseRs.data.forEach((item: { attributes: { name: string }; id: string }) => {
        endUseList.push({
          name: item.attributes.name,
          id: item.id,
        });
      });
      return endUseList;
    } catch (e) {
      return [];
    }
  }
);

export const buildingSlice = createSlice({
  name: 'building',
  initialState,
  reducers: {
    ...commonReduces,
  },
  extraReducers: (builder) => {
    builder
      .addCase(initPanelInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initPanelInfo.fulfilled, (state, action: any) => {
        state.status = 'idle';
        if (action.payload.panel.id) {
          state.currentPanelId = action.payload.panel.id;
        }
        if (action.payload) {
          if (action.payload.isNoResource) {
            state.currentPanel = null;
            const panel = state.panelList.find((panel) => panel.id === action.payload.panel.id);
            state.cxDoc = {
              ...defaultDoc,
              id: action.payload.panel.id,
              name: panel?.name ?? '',
              isNoResource: true,
            };
          } else {
            state.currentPanel = action.payload;
            state.cxDoc = initDoc(action.payload);
          }
        } else {
          state.currentPanel = null;
          state.cxDoc = { ...defaultDoc };
        }
      })
      .addCase(initPanelInfo.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(initEndUse.fulfilled, (state, action) => {
        state.endUses = action.payload;
      });
  },
});

export const {
  changeEditing,
  updateCxDoc,
  moveBreaker,
  splitBreaker,
  updateBreaker,
  updateCircuit,
  addBreaker,
  exchangeBreakerPosition,
  moveCircuit,
  saveBuildings,
  updateBuilding,
  saveIncludes,
  initPanelList,
  setHistoryList,
  setCurrentHistorical,
  setExpandView,
  changeHistoricalView,
} = buildingSlice.actions;

export const selectCurrentPanelId = (state: RootState) => state.building.currentPanelId;
export const selectCurrentPanel = (state: RootState) => state.building.currentPanel;
export const selectPanelEditing = (state: RootState) => state.building.editing;
export const selectCxDoc = (state: RootState) => state.building.cxDoc;
export const selectEndUse = (state: RootState) => state.building.endUses;
export const selectPanelList = (state: RootState) => state.building.panelList;
export const selectBuildingList = (state: RootState) => state.building.buildings;
export const selectIncludeList = (state: RootState) => state.building.include;
export const selectBuildingBriefList = (state: RootState) => state.building.buildingsBrief;
export const selectExpandedView = (state: RootState) => state.building.expandedView;
export const selectHistoricalView = (state: RootState) => state.building.historicalView;
export const selectCurrentHistoricalVersion = (state: RootState) =>
  state.building.historical?.currentVersion;
export const selectHistoryList = (state: RootState) => state.building.historical?.histories;
export const selectCurrentHisVersion = (state: RootState) =>
  state.building.historical?.currentVersion;
export const selectCurrentHistoricalCxDoc = (state: RootState) => state.building.historyCxDoc;

export default buildingSlice.reducer;
